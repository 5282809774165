@import "src/styles/utils";

.layout {
  min-height:90vh !important;
  section {
    flex: 1;
    min-height:75vh !important;
  }
  @include tabletDown() {
    margin-top: 0 !important;
    min-height:100vh !important;
    section {
      min-height:90vh !important;
    }
  }

  header {
    @include tabletDown() {
      display: none;
    }
  }
}