@import "styles/utils";

$footer-height-tablet: var(--footer-height-tablet);

.footerMobile {
  height: $footer-height-tablet;
  display: none;

  @include phone {
    display: block;
  }
}

.footerTabletDesktop {
  display: block;
  @include phone {
    display: none;
  }
}
